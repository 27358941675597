import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Modal from "react-modal";
import Swal from "sweetalert2";
import croix from "../../assets/images/btn-fermer.svg";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { LineWave } from "react-loader-spinner";
import { endpoints } from "../../utils/utils";

Modal.setAppElement("#root");
const customStyles = {
  content: {
    width: "50%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Message = () => {
  const axiosPrivate = useAxiosPrivate();
  const [loading, setloading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [titre, setTitre] = useState();
  const [messages, setMessages] = useState();
  const [id, setId] = useState(0);
  const [libelles, setLibelles] = useState({});
  const [totalPages, setTotalPages] = useState(0);

  const getMessage = async (currentPage) => {
    try {
      console.log(currentPage)
      const response = await axiosPrivate.get(
        `${endpoints.messages}?page=${currentPage+1}&limit=10`
      );
      const data = await response?.data?.messages;
      setTotalPages(response?.data?.totalPages);
      setMessages(data)
      return data;
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Une erreur est survenu au niveau du serveur",
      });
    }
  };

  useEffect(() => {
    getMessage(0);
  }, [currentPage]);

  const handleSubmit = async (e) => {
    setloading(true);
    e.preventDefault();

    try {
      console.log(id);
      const response = await axiosPrivate.post(
        endpoints.newMessage,
        JSON.stringify({ texte: titre }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      /* console.log(JSON.stringify(response?.data)); */
      if (response?.data) {
        setSuccessMsg("Message ajouté");
        closeModal("");
        console.log(response?.data);
        getMessage(0);
        setloading(false);
      }
    } catch (err) {
      setloading(false);
      /* setShowBtn(false)
            setActiveBtn("")
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Erreur de chargement');
            }
            setShowBtn(false)

            setActiveBtn("") */
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Une erreur est survenu au niveau du serveur",
      });
    }
    /*  console.log(doc) */
  };

  const openModal = (item) => {
    console.log(item);
    setloading(false);
    if (item != null) {
      setId(item?.id);
      setTitre(item?.lang);
    } else {
      setTitre("");
      setId(0);
    }

    setIsOpen(true);
  };
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }
  function closeModal() {
    setIsOpen(false);
    setloading(false);
    setId(0);
    setTitre("");
    setLibelles({});
  }

  const PER_PAGE = 10;
  const offset = currentPage * PER_PAGE;
  const currentPageData = messages
    ?.slice(offset, offset + PER_PAGE)
    ?.map((item) => (
      <>
        <tr key={item?.id}>
          <td>{item?.texte}</td>
          <td>{item?.convertDate}</td>
        </tr>
      </>
    ));
  const pageCount = Math.ceil(messages?.length / PER_PAGE);
  

  const handlePageClick = async (data) => {
    //setCurrentPage(selectedPage);
    const fansFromApi = await getMessage(data.selected);
    setMessages(fansFromApi)
  }

  return (
    <>
      <div className="content-page">
        <div className="header">
          <h1>Gestion Messages</h1>
          <div className="notifications">
            <div className="icons"></div>
          </div>
        </div>

        <div className="btn-add-form bloc-header-user">
          <label>Messages</label>
        </div>

        <div className="content-listes">
          <table className="tableau-list">
            <thead>
              <tr>
                <th>Titre</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>{currentPageData}</tbody>
          </table>
        </div>
        <ReactPaginate
          previousLabel={"←"}
          nextLabel={"→"}
          pageCount={totalPages}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
        <a href="#0" className="add-user" onClick={() => openModal(null)}>
          Ajouter un Message
        </a>

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="contentModal contentContenu">
            <div className="headerModal">
              <h2>Ajouter un Message</h2>
              <a onClick={closeModal}>
                <img src={croix} alt="Fermer" />
              </a>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="col-md-6">
                <div className="form-text">
                  <label>Message</label>
                  <input
                    required
                    type="text"
                    placeholder="Dorem ipsum dolorI "
                    onChange={(e) => setTitre(e.target.value)}
                    value={titre}
                  />
                </div>
              </div>
              <div className="form-button">
                {loading ? (
                  <LineWave color={"#034B27"} loading={loading} size={50} />
                ) : (
                  <button name="">Envoyer</button>
                )}
              </div>
            </form>
          </div>
        </Modal>
      </div>
    </>
  );
};
export default Message;
